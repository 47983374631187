import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// import core components
import { LinkButton, ImageBanner } from 'components';
import { CardList } from 'components/CardList';
import { Layout, Container } from 'layouts';
import { queryHelper, nodeHelper } from 'helpers';

export const Wrapper = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    margin: 0 20px;
  }
`;

export const StyledBackgroundImage = styled.div`
  display: flex;

  margin-bottom: 45px;
  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: 0px 0px 15px rgba(0, 22, 79, 0.1);
  overflow: hidden;

  @media all and (max-width: ${props => props.theme.breakpoints.l}) {
    margin: 0 20px 20px;
  }
`;

const BrandsPage = ({ data: { topBanner, brands } }) => {
  const nodesBrands = nodeHelper.filterNodes(brands.edges.map(edge => edge.node)); // filter catalogs to remove blank catalogs
  return (
    <Layout title="Greeting Cards">
      <Container>
        <StyledBackgroundImage>
          <GatsbyImage image={getImage(queryHelper.getGatsbyImageData(topBanner))} alt=""/>
        </StyledBackgroundImage>
        <Wrapper>
          <LinkButton text="Back to Home" link="/" spacing={true} />
        </Wrapper>
        {/* <Header pageName="Select a category" /> */}
        <CardList size={'large'} photos={nodeHelper.toCategories(nodesBrands)} /> {/* Brands filter */}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query MyBrandPageQuery {
    topBanner: nodeBanner(title: { eq: "catalogTopBanner" }) {
      drupal_id
      title
      field_display_title
      field_description
      field_url
      relationships {
        field_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [WEBP]
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
    brands: allTaxonomyTermBrand {
        edges {
          node {
            name
            field_url
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [WEBP]
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
  }
`;
export default BrandsPage;
